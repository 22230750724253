import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { Container, Snackbar } from '@mui/material';

import { RecipeplannerApi, getRecipeById } from '../../api';
import { RecipeUpdateInputDto } from '../../api/generated';
import { CircularLoader, RecipeEditForm } from '../../components';
import { useAppSelector } from '../../store';
import { Recipe } from '../../types';

export const RecipeEditPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [editedRecipe, setEditedRecipe] = useState<Recipe | null>(null);
  const [dataUpdated, setDataUpdated] = useState<boolean>(false);
  const { recipesId } = useParams();
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const userId = useAppSelector((state) => state.auth.user?.id);
  const isAuthenticated = !!userId;

  useEffect(() => {
    const fetchRecipeDetails = async () => {
      try {
        if (!recipesId) {
          setError('No recipe ID provided');
          return;
        }

        setLoading(true);
        const response = await getRecipeById(recipesId);
        setEditedRecipe(response);
      } catch (error) {
        setError(`Error fetching recipe details: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    if (recipesId) {
      fetchRecipeDetails();
    } else {
      setError('No recipe ID provided');
    }
  }, [recipesId, dataUpdated]);

  const handleSnackbarClose = () => {
    setShowSuccessMessage(false);
  };

  const handleSave = async (editedRecipe: Recipe) => {
    try {
      const ingredients = editedRecipe.ingredients.map((ingredient) => ({
        id: ingredient.id ?? '',
        name: ingredient.name,
        quantity: ingredient.quantity ?? '',
      }));

      const instructions = editedRecipe.instructions.map(
        (instruction, index) => ({
          id: editedRecipe.instructions[index].id ?? '',
          text: instruction.text,
        }),
      );

      const recipeUpdateData: RecipeUpdateInputDto = {
        name: editedRecipe.name,
        cooking_time_minutes: editedRecipe.cookingTimeMinutes,
        complexity: editedRecipe.complexity,
        calories_estimate: editedRecipe.caloriesEstimate,
        ingredients,
        instructions,
      };

      await RecipeplannerApi.updateRecipe(editedRecipe.id, recipeUpdateData);

      setEditedRecipe(editedRecipe);
      setShowSuccessMessage(true);
      setDataUpdated(true);

      navigate(`/recipes/${editedRecipe.id}`);
    } catch (error) {
      setError(`Error updating recipe: ${error}`);
    }
  };

  if (loading || !editedRecipe) {
    return <CircularLoader />;
  }

  if (!isAuthenticated || editedRecipe.createdBy?.id !== userId) {
    return <Navigate to="/unauthorized" />;
  }

  return (
    <Container>
      <RecipeEditForm
        recipe={editedRecipe}
        isEditable
        onSave={handleSave}
        onCancel={() => navigate(`/recipes/${editedRecipe.id}`)}
      />
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Recipe saved successfully!"
      />
      {error && <Snackbar open message={error} />}
    </Container>
  );
};
