import { Link } from 'react-router-dom';

import { Container, Typography } from '@mui/material';

export const UnauthenticatedPage = () => {
  return (
    <Container maxWidth="sm">
      <Typography variant="h6" component="h2" gutterBottom>
        You are not logged in
      </Typography>
      <Typography variant="body1" paragraph>
        Please click <Link to="/auth">here</Link> to log in.
      </Typography>
    </Container>
  );
};
