import React, { useEffect } from 'react';

import { Grid } from '@mui/material';

import { RecipeTile } from '..';
import {
  addFavoriteAsync,
  bulkUpdateFavorites,
  removeFavoriteAsync,
  useAppDispatch,
} from '../../store';
import { Recipe } from '../../types';

interface RecipeListProps {
  recipes: Recipe[];
  onPress: (recipeId: string) => void;
  onFavoriteToggle: (recipes: Recipe[]) => void;
}

export const RecipeList: React.FC<RecipeListProps> = ({
  recipes,
  onPress,
  onFavoriteToggle,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(bulkUpdateFavorites(recipes));
  }, [recipes, dispatch]);

  const handleFavoriteToggle = async (recipe: Recipe) => {
    const updatedRecipe = { ...recipe, isFavorite: !recipe.isFavorite };
    onFavoriteToggle(
      recipes.map((r) => {
        if (r.id === recipe.id) {
          return updatedRecipe;
        }

        return r;
      }),
    );

    try {
      if (recipe.isFavorite) {
        await dispatch(removeFavoriteAsync(recipe));
      } else {
        await dispatch(addFavoriteAsync(recipe));
      }
    } catch (err) {
      console.error(
        `Failed to update favorite for recipe id: ${recipe.id}`,
        err,
      );
      onFavoriteToggle(
        recipes.map((r) => {
          if (r.id === recipe.id) {
            return updatedRecipe;
          }

          return r;
        }),
      );
    }
  };

  return (
    <Grid container spacing={1} width="100%">
      {recipes.map((recipe) => (
        <Grid item key={recipe.id} xs={6} sm={4} md={3} lg={2}>
          <RecipeTile
            title={recipe.name}
            imageUrl={recipe.images[0]?.url}
            recipeId={recipe.id}
            onPress={() => onPress(recipe.id)}
            isFavorite={recipe.isFavorite}
            onFavoriteToggle={() => handleFavoriteToggle(recipe)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
