import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'supertokens-auth-react/recipe/thirdparty';

import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';

import Profile from '../../assets/icons8-customer-48.png';
import { setAuthUser, useAppDispatch, useAppSelector } from '../../store';

export const UserMenu = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) setAnchorElUser(null);
  }, [isAuthenticated]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogin = () => {
    handleCloseUserMenu();
    navigate('/auth', { state: { from: location.pathname } });
  };

  const handleLogout = async () => {
    try {
      await signOut();
      dispatch(setAuthUser(null));
      handleCloseUserMenu();
      navigate(window.location.pathname, { replace: true });
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return (
    <>
      {isAuthenticated ? (
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar
              style={{ height: '50px', width: '50px' }}
              alt={user?.firstName ?? 'Profile'}
              src={user?.imageUrl ?? Profile}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Login">
          <IconButton onClick={handleLogin} sx={{ p: 0 }}>
            <Typography
              variant="body1"
              component="span"
              sx={{
                color: 'white',
                textDecoration: 'none',
                fontWeight: 'bold',
              }}
            >
              Login
            </Typography>
          </IconButton>
        </Tooltip>
      )}
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {isAuthenticated && (
          <MenuItem onClick={handleLogout}>
            <Typography variant="body1">Logout</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
