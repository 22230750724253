import React from 'react';

import { Container, Link, Typography } from '@mui/material';

export const TermsAndConditionsPage = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom align="left">
        Terms and Conditions
      </Typography>
      <Typography variant="body1" paragraph>
        These terms and conditions outline the rules and regulations for the use
        of our Meal Planner App, accessible from{' '}
        <Link href="https://mealplanner.brijesh.app/">
          https://mealplanner.brijesh.app/
        </Link>
      </Typography>

      <Typography variant="h5" gutterBottom>
        Accepting these Terms
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing or using the Meal Planner App, you agree to be bound by all
        of the terms and conditions outlined herein. If you do not agree to all
        of these terms, please do not use the service. If any term does not make
        sense or you require clarification, please contact us at{' '}
        <Link href="mailto:bk10895@gmail.com">bk10895@gmail.com</Link>.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Changes to these Terms
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to modify these Terms at any time. Any changes made
        to these Terms will become effective immediately upon posting, unless
        otherwise stated. It is your responsibility to review the Terms
        periodically for any changes. Your continued use of the Meal Planner App
        following the posting of changes constitutes acceptance of those
        changes.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        Please refer to our <Link href="/privacy-policy">Privacy Policy</Link>{' '}
        for information on how we collect and use information about users of the
        Meal Planner App.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Third-Party Services
      </Typography>
      <Typography variant="body1" paragraph>
        The Meal Planner App may contain links to third-party websites or
        services that are not owned or controlled by us. Your use of such
        third-party services is subject to the terms and conditions and privacy
        policies of the respective third parties. We have no control over and
        assume no responsibility for the content, privacy policies, or practices
        of any third-party websites or services.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Creating Accounts
      </Typography>
      <Typography variant="body1" paragraph>
        When you create an account with us, you agree to maintain the security
        of your password and accept all risks of unauthorized access to any data
        or other information you provide to the Meal Planner App. Please notify
        us immediately of any security breaches or unauthorized access.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Your Content & Conduct
      </Typography>
      <Typography variant="body1" paragraph>
        Our Service allows you and other users to post, link, and otherwise make
        available content. You are responsible for the content you make
        available, including its legality and appropriateness. By posting
        content, you grant us the right and license to use, reproduce, modify,
        and distribute your content. You may not post any content that is
        illegal, infringes upon the rights of others, or violates these Terms.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Meal Planner Materials
      </Typography>
      <Typography variant="body1" paragraph>
        All materials included in the Meal Planner App, including designs, text,
        graphics, and other content, are owned by us or our licensors and are
        protected by copyright laws. You are granted the right to use these
        materials for personal, non-commercial purposes only. Any unauthorized
        use of these materials may violate copyright laws and result in legal
        action.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Hyperlinks and Third-Party Content
      </Typography>
      <Typography variant="body1" paragraph>
        You may create hyperlinks to the Meal Planner App, but you may not use
        our trademarks or proprietary information without our consent. We do not
        endorse or take responsibility for any third-party websites or content
        linked to or from the Meal Planner App.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Unavoidable Legal Stuff
      </Typography>
      <Typography variant="body1" paragraph>
        The Meal Planner App and all content are provided on an &quot;as
        is&quot; basis without warranties of any kind, whether express or
        implied. We shall not be liable for any damages arising from the use of
        the Meal Planner App or any content therein. By using the Meal Planner
        App, you agree to indemnify us from any claims arising from your use of
        the service or violation of these Terms.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Copyright Complaints
      </Typography>
      <Typography variant="body1" paragraph>
        We respect the intellectual property rights of others. If you believe
        that any content on the Meal Planner App violates your copyright, please
        contact us at{' '}
        <Link href="mailto:bk10895@gmail.com">bk10895@gmail.com</Link> to report
        the infringement.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Governing Law and Jurisdiction
      </Typography>
      <Typography variant="body1" paragraph>
        Rest assured that these Terms and any disputes arising from them will be
        confidently governed by the laws of India in the Mirzapur court of the
        Uttar Pradesh region. You agree that any legal action or proceeding
        arising out of or related to the Meal Planner App shall be brought
        exclusively in the courts of your country, and you consent to the
        jurisdiction of such courts.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Termination
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to terminate or suspend your account and access to
        the Meal Planner App for any reason without prior notice or liability.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Entire Agreement
      </Typography>
      <Typography variant="body1" paragraph>
        These Terms constitute the entire agreement between you and Meal Planner
        App creator regarding the use of the Meal Planner App, superseding any
        prior agreements or understandings between you and the app creator.
        relating to the Meal Planner App.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Feedback
      </Typography>
      <Typography variant="body1" paragraph>
        We value your feedback. Please contact us at{' '}
        <Link href="mailto:bk10895@gmail.com">bk10895@gmail.com</Link> with any
        comments, suggestions, or concerns about the Meal Planner App or these
        Terms. By providing feedback, you grant us the right to use your
        feedback for any purpose without compensation.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Questions & Contact Information
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about these Terms, please contact us at{' '}
        <Link href="mailto:bk10895@gmail.com">bk10895@gmail.com</Link>.
      </Typography>
    </Container>
  );
};
