import Session from 'supertokens-auth-react/recipe/session';

import axios from 'axios';

import { Configuration, DefaultApi as _RecipeplannerApi } from './generated';

const apiAxiosInstance = axios.create();

apiAxiosInstance.interceptors.request.use(
  (config) => {
    return Session.getAccessToken().then((accessToken) => {
      const innerConfig = { ...config };
      innerConfig.headers = Object.assign(innerConfig.headers, {
        Authorization: `Bearer ${accessToken}`,
      });
      return innerConfig;
    });
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const RecipeplannerApi = new _RecipeplannerApi(
  new Configuration(),
  process.env.REACT_APP_MEALPLANNER_API_HOST ?? 'http://localhost:8000',
  apiAxiosInstance,
);
