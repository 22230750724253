import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#5571d6',
    },
    error: {
      main: '#FF1744',
    },
  },
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 8,
          },
          padding: 8,
        },
      },
    },
  },
});

export default theme;
