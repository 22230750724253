import React, { useMemo } from 'react';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { useWindowSize } from '@uidotdev/usehooks';

import { CategoryType, Ingredient, Instruction } from '../../types';

interface RecipeInfoTileProps {
  title: string;
  imageUrl: string;
  steps: Instruction[];
  ingredients: Ingredient[];
  cookingTime: number;
  complexity: string;
  calories: number;
  cuisineType: CategoryType;
  createdBy: string;
  isFavorite: boolean;
  onFavoriteToggle: () => void;
}
export const RecipeInfoTile: React.FC<RecipeInfoTileProps> = ({
  title,
  imageUrl,
  steps,
  ingredients,
  cookingTime,
  complexity,
  calories,
  cuisineType,
  createdBy,
  isFavorite,
  onFavoriteToggle,
}) => {
  const windowWidth = useWindowSize().width ?? 300;
  const isLargeScreen = useMemo(() => windowWidth > 600, [windowWidth]);
  const thumbnailSize = useMemo(
    () => (isLargeScreen ? `300px` : `${windowWidth - 40}px`),
    [isLargeScreen, windowWidth],
  );

  const handleFavoriteToggle = () => {
    onFavoriteToggle();
  };

  return (
    <Box my={1}>
      <Box
        display="flex"
        flexDirection={isLargeScreen ? 'row' : 'column'}
        alignItems="flex-start"
      >
        <img
          src={imageUrl}
          alt={title}
          style={{
            width: thumbnailSize,
            height: thumbnailSize,
            marginRight: isLargeScreen ? '16px' : '0',
            borderRadius: '8px',
          }}
        />
        <Box flex={1}>
          <Typography gutterBottom variant="h5" component="div" align="left">
            {title}
          </Typography>
          {isFavorite && (
            <Typography variant="body2" align="left" color="text.secondary">
              It&apos;s your one of favorite recipe.
            </Typography>
          )}
          <Tooltip
            title={isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
          >
            <IconButton
              aria-label={
                isFavorite ? 'Remove from Favorites' : 'Add to Favorites'
              }
              onClick={handleFavoriteToggle}
              sx={{ flexShrink: 1, alignSelf: 'flex-start' }}
            >
              {isFavorite ? (
                <FavoriteIcon color="primary" />
              ) : (
                <FavoriteBorderIcon />
              )}
            </IconButton>
          </Tooltip>
          <Typography variant="body2" align="left" color="text.secondary">
            Cuisine: {cuisineType.name}
          </Typography>
          <Typography variant="body2" align="left" color="text.secondary">
            Calories: {calories}
          </Typography>
          <Typography variant="body2" align="left" color="text.secondary">
            Complexity: {complexity}
          </Typography>
          <Typography variant="body2" align="left" color="text.secondary">
            Cooking Time: {cookingTime} Minutes
          </Typography>
          <Typography variant="body2" align="left" color="text.secondary">
            Created By: {createdBy}
          </Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mt: 1, mb: 2, textAlign: 'left' }}
          >
            Ingredients:
          </Typography>
          <Box
            display="flex"
            flexDirection={isLargeScreen ? 'row' : 'row'}
            alignItems="flex-start"
            flexWrap="wrap"
            sx={{
              '& > *': {
                mb: isLargeScreen ? '10px' : '8px',
                mr: isLargeScreen ? '8px' : '0',
                textAlign: 'left',
              },
            }}
          >
            {ingredients.map((ingredient) => (
              <Chip
                key={ingredient.id}
                label={ingredient.name}
                sx={{
                  mb: '8px',
                  mr: '8px',
                  textTransform: 'capitalize',
                }}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box mt={1}>
        <Typography variant="body2" color="text.secondary" align="left">
          Steps:
        </Typography>
        <Box ml={isLargeScreen ? '0' : '8px'} mr={isLargeScreen ? '0' : '8px'}>
          {steps.map((step, index) => (
            <Typography
              key={step.id}
              variant="body2"
              color="text.secondary"
              sx={{ mt: 1, ml: '0', mr: '0', textAlign: 'left' }}
            >
              {` ${index + 1}. ${step.text}`}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
