import React from 'react';

import { Container, Typography, useTheme } from '@mui/material';

interface PageHeaderProps {
  title: string;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  const theme = useTheme();

  const headerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  };

  const titleStyle: React.CSSProperties = {
    fontSize: theme.typography.h5.fontSize,
  };

  return (
    <Container style={headerStyle}>
      <Typography style={titleStyle}>{title}</Typography>
    </Container>
  );
};
