import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RecipeplannerApi } from '../../api';
import { Recipe } from '../../types';

interface FavoritesState {
  favoriteRecipes: { [id: string]: Recipe };
}

const initialState: FavoritesState = {
  favoriteRecipes: {},
};

export const addFavoriteAsync = createAsyncThunk<void, Recipe>(
  'favorites/addFavoriteAsync',
  async (recipe, { dispatch }) => {
    await RecipeplannerApi.addFavoriteRecipe(recipe.id);
    dispatch(addFavorite(recipe));
  },
);

export const removeFavoriteAsync = createAsyncThunk<void, Recipe>(
  'favorites/removeFavoriteAsync',
  async (recipe, { dispatch }) => {
    await RecipeplannerApi.removeFavoriteRecipe(recipe.id);
    dispatch(removeFavorite(recipe));
  },
);

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    addFavorite: (state, action: PayloadAction<Recipe>) => {
      const recipe = action.payload;
      state.favoriteRecipes[recipe.id] = recipe;
    },
    removeFavorite: (state, action: PayloadAction<Recipe>) => {
      const recipe = action.payload;
      if (state.favoriteRecipes[recipe.id]) {
        delete state.favoriteRecipes[recipe.id];
      }
    },
    bulkUpdateFavorites: (state, action: PayloadAction<Recipe[]>) => {
      for (const recipe of action.payload) {
        if (recipe.isFavorite) {
          state.favoriteRecipes[recipe.id] = recipe;
        } else {
          delete state.favoriteRecipes[recipe.id];
        }
      }
    },
    updateFavorite: (state, action: PayloadAction<Recipe>) => {
      const recipe = action.payload;
      if (recipe.isFavorite) {
        state.favoriteRecipes[recipe.id] = recipe;
      } else {
        delete state.favoriteRecipes[recipe.id];
      }
    },
  },
});

export const {
  addFavorite,
  removeFavorite,
  bulkUpdateFavorites,
  updateFavorite,
} = favoritesSlice.actions;
export default favoritesSlice.reducer;
