import SuperTokens from 'supertokens-auth-react';
import Session from 'supertokens-auth-react/recipe/session';
import ThirdParty, { Google } from 'supertokens-auth-react/recipe/thirdparty';

export const InitializeSuperTokens = () => {
  SuperTokens.init({
    appInfo: {
      appName: 'Supertoken Login App',
      apiDomain:
        process.env.REACT_APP_MEALPLANNER_API_HOST ?? 'http://localhost:8000',
      websiteDomain:
        process.env.REACT_APP_MEALPLANNER_APP_HOST ?? 'http://localhost:3000',
      apiBasePath: '/auth',
      websiteBasePath: '/auth',
    },

    recipeList: [
      ThirdParty.init({
        signInAndUpFeature: {
          providers: [Google.init()],
        },
        override: {
          functions: (oI) => {
            return {
              ...oI,
              getAuthStateFromURL: (input) => {
                // if the user is logged in via google one tap, return the auth state
                if (input.userContext?.login_method === 'google_one_tap') {
                  return input.userContext.state;
                }
                // otherwise, return the default auth state
                return oI.getAuthStateFromURL(input);
              },
            };
          },
        },
        preAPIHook: async (context) => {
          if (context.action === 'THIRD_PARTY_SIGN_IN_UP') {
            // don't do anything if the user is not logged in via google one tap
            if (context.userContext?.login_method !== 'google_one_tap') {
              return context;
            }

            if (typeof context.requestInit.body !== 'string') {
              throw new Error('should not happen');
            }
            const body = JSON.parse(context.requestInit.body);

            body.redirectURIInfo = undefined;
            body.oAuthTokens = {
              id_token: context.userContext.id_token,
            };

            context.requestInit.body = JSON.stringify(body);
          }

          return context;
        },
      }),
      Session.init(),
    ],
  });
};
