import { CategoryType, Recipe } from '../../types';
import { RecipeplannerApi } from '../api-client';
import { CategoryDto, RecipeDto } from '../generated';

const transformCategoryResponseToCategory = (
  category: CategoryDto,
): CategoryType => ({
  id: category.id,
  name: category.name,
});

export const getCategories = async (): Promise<CategoryType[]> => {
  const { data } = await RecipeplannerApi.getAllCategories();
  return data.map(transformCategoryResponseToCategory);
};

export const getCategoryById = async (id: string): Promise<CategoryType> => {
  const { data } = await RecipeplannerApi.getCategoryById(id);
  return transformCategoryResponseToCategory(data);
};

const transformRecipeResponseToMeal = (recipe: RecipeDto): Recipe => ({
  id: recipe.id,
  name: recipe.name,
  description: recipe.description,
  images: recipe.images.map((image) => ({ id: image.id, url: image.url })),
  category: {
    id: recipe.category.id,
    name: recipe.category.name,
  },
  ingredients: recipe.ingredients.map((ingredient) => ({
    id: ingredient.id,
    name: ingredient.name,
    quantity: ingredient.quantity,
  })),
  instructions: recipe.instructions.map((step) => ({
    id: step.id,
    text: step.text,
  })),
  cookingTimeMinutes: recipe.cooking_time_minutes,
  complexity: recipe.complexity,

  caloriesEstimate: recipe.calories_estimate,

  createdBy: {
    id: recipe.created_by.id,
    firstName: recipe.created_by.first_name,
    lastName: recipe.created_by.last_name,
    imageUrl: recipe.created_by.image_url,
  },
  isFavorite: recipe.isFavorite ?? false,
});

export const getRecipes = async (): Promise<Recipe[]> => {
  const { data } = await RecipeplannerApi.getAllRecipes();
  return data.map(transformRecipeResponseToMeal);
};

export const getRecipeById = async (id: string): Promise<Recipe> => {
  const { data } = await RecipeplannerApi.getRecipeById(id);
  return transformRecipeResponseToMeal(data);
};

export const getRecipesByCategoryId = async (
  categoryId: string,
): Promise<Recipe[]> => {
  const { data } = await RecipeplannerApi.getRecipesByCategoryId(categoryId);
  return data.map(transformRecipeResponseToMeal);
};

export const getFavoriteRecipes = async (): Promise<Recipe[]> => {
  const { data } = await RecipeplannerApi.getFavoriteRecipes();
  return data.map(transformRecipeResponseToMeal);
};

export const getMyRecipes = async (): Promise<Recipe[]> => {
  const { data } = await RecipeplannerApi.getMyRecipes();
  return data.map(transformRecipeResponseToMeal);
};

export const getRecipesByUserId = async (userId: string): Promise<Recipe[]> => {
  const { data } = await RecipeplannerApi.getRecipesByUserId(userId);
  return data.map(transformRecipeResponseToMeal);
};
