import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Grid, Typography, useTheme } from '@mui/material';

import { RecipeplannerApi } from '../../api';
import { CategoryTile, CircularLoader, PageHeader } from '../../components';
import { CategoryType } from '../../types';

export const RecipeCategoriesPage = () => {
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await RecipeplannerApi.getAllCategories();
        setCategories(response.data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handlePress = (categoryId: string) => {
    navigate(`/category/${categoryId}`);
  };

  if (loading) return <CircularLoader />;
  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <Container>
      <PageHeader title="Recipe Categories" />
      <Grid container spacing={1}>
        {categories.map((category) => (
          <Grid item key={category.id} xs={6} md={4} lg={3}>
            <CategoryTile
              title={category.name}
              color={theme.palette.secondary.main}
              categoryId={category.id}
              onPress={() => handlePress(category.id)}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
