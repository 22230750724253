import { Link } from 'react-router-dom';

import { Button, Container, Typography } from '@mui/material';

export const UnauthorizedPage = () => {
  return (
    <Container maxWidth="sm">
      <Typography variant="h6" component="h2" gutterBottom>
        You do not have permission to access this page.{' '}
      </Typography>

      <Typography variant="body1" paragraph>
        Please click <Link to="/my-recipes">here</Link> to go to your recipes
        page.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/my-recipes"
      >
        Go to My Recipes
      </Button>
    </Container>
  );
};
