import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Typography } from '@mui/material';

import { RecipeplannerApi } from '../../api';
import { CircularLoader, PageHeader, RecipeGenerator } from '../../components';
import { Recipe } from '../../types';

export const AddRecipePage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleGenerateRecipe = async (inputText: string) => {
    setLoading(true);
    setError('');

    try {
      const response = await RecipeplannerApi.create({ query: inputText });
      const apiRecipe = response.data;

      const recipeData: Recipe = {
        ...apiRecipe,
        isFavorite:
          apiRecipe.isFavorite === undefined ? false : apiRecipe.isFavorite,
        cookingTimeMinutes: 0,
        caloriesEstimate: 0,
      };
      navigate(`/recipes/${recipeData.id}`);
    } catch (err: unknown) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <PageHeader title="Add Recipe" />
      <RecipeGenerator onGenerateRecipe={handleGenerateRecipe} />
      <Box display="flex" alignItems="flex-start">
        {loading && <CircularLoader />}
        {error && (
          <>
            <Typography>{error}</Typography>
            <Button
              onClick={() => handleGenerateRecipe('')}
              variant="contained"
            >
              Retry
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};
