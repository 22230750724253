import React, { useMemo } from 'react';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useWindowSize } from '@uidotdev/usehooks';

interface RecipeTileProps {
  title: string;
  imageUrl: string;
  recipeId: string;
  onPress: () => void;
  isFavorite: boolean;
  onFavoriteToggle: () => void;
}

export const RecipeTile: React.FC<RecipeTileProps> = ({
  title,
  imageUrl,
  recipeId,
  onPress,
  isFavorite,
  onFavoriteToggle,
}) => {
  const handleFavoriteToggle = () => {
    onFavoriteToggle();
  };

  const windowWidth = useWindowSize().width ?? 300;
  const thumbnailSize = useMemo(
    () =>
      windowWidth < 600 ? `${Math.floor((windowWidth - 75) / 2)}px` : '180px',
    [windowWidth],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Card
        onClick={onPress}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          width: thumbnailSize,
          transition: 'transform 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        <CardMedia
          component="img"
          image={imageUrl}
          alt={title}
          sx={{
            height: thumbnailSize,
            width: thumbnailSize,
            objectFit: 'cover',
          }}
        />
        <CardContent
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography
              variant="h6"
              component="p"
              sx={{ flexGrow: 1, fontSize: '1rem', textAlign: 'left' }}
            >
              {title}
            </Typography>
            <Tooltip
              title={isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
              placement="top"
              arrow
            >
              <IconButton
                aria-label={
                  isFavorite ? 'Remove from Favorites' : 'Add to Favorites'
                }
                onClick={(event) => {
                  event.stopPropagation();
                  handleFavoriteToggle();
                }}
                sx={{ flexShrink: 1, alignSelf: 'flex-start' }}
              >
                {isFavorite ? (
                  <FavoriteIcon color="primary" />
                ) : (
                  <FavoriteBorderIcon />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
