import React, { ChangeEvent, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

interface RecipeGeneratorProps {
  onGenerateRecipe: (inputText: string) => void;
}

export const RecipeGenerator: React.FC<RecipeGeneratorProps> = ({
  onGenerateRecipe,
}) => {
  const [inputText, setInputText] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const handleGenerateRecipe = () => {
    if (inputText.trim() !== '') {
      onGenerateRecipe(inputText);
      setErrorMessage('');
    } else {
      // Display error message for empty input
      setErrorMessage(
        'Input text is empty. Please enter text for recipe generation.',
      );
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      py={2}
      px={3}
    >
      <Typography variant="h6" gutterBottom>
        Recipe Generator
      </Typography>
      <TextField
        variant="outlined"
        multiline
        value={inputText}
        onChange={handleInputChange}
        placeholder="Enter text for recipe generation"
        style={{ marginBottom: '10px', width: '100%' }}
      />
      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerateRecipe}
      >
        Generate Recipe
      </Button>
    </Box>
  );
};
