import * as reactRouterDom from 'react-router-dom';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ThirdPartyPreBuiltUI } from 'supertokens-auth-react/recipe/thirdparty/prebuiltui';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';

import {
  AddRecipePage,
  FavoriteRecipesPage,
  MyRecipesPage,
  NotFound,
  PrivacyPage,
  RecipeCategoriesPage,
  RecipeDetailsPage,
  RecipeEditPage,
  RecipeListPage,
  RecipesPage,
  TermsAndConditionsPage,
  UnauthenticatedPage,
  UnauthorizedPage,
} from '../pages';
import { PrivateRoutes } from './PrivateRoutes';

export const AppRouter = () => {
  return (
    <Routes>
      {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
        ThirdPartyPreBuiltUI,
      ])}
      <Route path="/" element={<Navigate to="/recipes" />} />
      <Route path="/recipes" element={<RecipesPage />} />
      <Route path="/categories" element={<RecipeCategoriesPage />} />
      <Route path="/category/:categoryId" element={<RecipeListPage />} />
      <Route path="/recipes/:recipesId" element={<RecipeDetailsPage />} />
      <Route path="/recipes/:recipesId/edit" element={<RecipeEditPage />} />
      <Route path="/favorites" element={<FavoriteRecipesPage />} />
      <Route path="*" element={<Navigate to="/not-found" />} />
      <Route path="/not-found" element={<NotFound />} />
      <Route path="/not-authenticated" element={<UnauthenticatedPage />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="/privacy-policy" element={<PrivacyPage />} />
      <Route
        path="/terms-and-conditions"
        element={<TermsAndConditionsPage />}
      />
      <Route
        path="/addrecipe"
        element={
          <PrivateRoutes>
            <AddRecipePage />
          </PrivateRoutes>
        }
      />
      <Route path="/myrecipes" element={<MyRecipesPage />} />
    </Routes>
  );
};
