import { BrowserRouter } from 'react-router-dom';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import ThirdParty, {
  ThirdpartyComponentsOverrideProvider,
} from 'supertokens-auth-react/recipe/thirdparty';

import './App.css';
import { CircularLoader, Footer, Navbar } from './components';
import GoogleOneTapLogin from './google-one-tap';
import { useAuthContext } from './hooks';
import { AppRouter } from './routes';

function App() {
  const authContext = useAuthContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const doLogin = async (data: any) => {
    if (data.credential) {
      // we do this call so that the state is created and saved in storage.
      await ThirdParty.getAuthorisationURLWithQueryParamsAndSetState({
        thirdPartyId: 'google',
        frontendRedirectURI: window.location.toString(),
      });

      const stateInfo = await ThirdParty.getStateAndOtherInfoFromStorage();
      if (data.credential && stateInfo !== undefined) {
        await ThirdParty.signInAndUp({
          userContext: {
            id_token: data.credential,
            state: stateInfo.stateForAuthProvider,
            login_method: 'google_one_tap',
          },
        });
        document.location.href = '/';
      }
    }
  };

  if (!authContext.isReady) {
    return <CircularLoader />;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <SuperTokensWrapper>
          <ThirdpartyComponentsOverrideProvider
            components={{
              // In this case, the <ThirdPartyPasswordlessHeader_Override>
              // will render the original component
              // wrapped in a div with an octocat picture above it.
              ThirdPartySignInAndUpProvidersForm_Override: ({
                DefaultComponent,
                providers,
                ...props
              }) => {
                return (
                  <div>
                    {(() => {
                      return (
                        <div>
                          <div id="google-onetap-container" />
                          <GoogleOneTapLogin
                            onSuccess={doLogin}
                            googleAccountConfigs={{
                              client_id:
                                '845033048795-o32df3crk0n1bsprrnr5dbv4fsu7uduk.apps.googleusercontent.com',
                              prompt_parent_id: 'google-onetap-container',
                              cancel_on_tap_outside: false,
                            }}
                          />
                          <DefaultComponent providers={providers} {...props} />
                        </div>
                      );
                    })()}
                  </div>
                );
              },
            }}
          >
            <div className="App-header">
              <Navbar />
            </div>
            <div className="App-container">
              <div className="App-content">
                <AppRouter />
              </div>
              <div className="App-footer">
                <Footer />
              </div>
            </div>
          </ThirdpartyComponentsOverrideProvider>
        </SuperTokensWrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
