import React from 'react';

import { Container, Link, Typography } from '@mui/material';

export const PrivacyPage = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom align="left">
        PRIVACY POLICY
      </Typography>
      <Typography variant="body1" paragraph>
        Last Revised: March 2024
      </Typography>
      <Typography variant="body1" paragraph>
        Our privacy policy applies to information we collect when you use or
        access our website, application, or just interact with us. We may change
        this privacy policy from time to time. Whenever we make changes to this
        privacy policy, the changes are effective immediately after we post the
        revised privacy policy (as indicated by revising the date at the top of
        our privacy policy). We encourage you to review our privacy policy
        whenever you access our services to stay informed about our information
        practices and the ways you can help protect your privacy.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Collection of Information
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Information You Provide to Us</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        We collect information you provide directly to us. For example, we
        collect information when you participate in any interactive features of
        our services, fill out a form, request customer support, provide any
        contact or identifying information or otherwise communicate with us. The
        types of information we may collect include your name, email address,
        postal address, credit card information and other contact or identifying
        information you choose to provide.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>
          Information We Collect Automatically When You Use the Services
        </strong>
      </Typography>
      <Typography variant="body1" paragraph>
        When you access or use our services, we automatically collect
        information about you, including log information, device information,
        location information, and information collected by cookies and other
        tracking technologies.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Information We Collect From Other Sources</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        In order to provide you with access to the Service, or to provide you
        with better service in general, we may combine information obtained from
        other sources and combine that with information we collect through our
        services.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Use of Information
      </Typography>
      <Typography variant="body1" paragraph>
        We use information about you for various purposes, including to provide,
        maintain and improve our services, respond to your comments, questions
        and requests, and personalize and improve our services.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Sharing of Information
      </Typography>
      <Typography variant="body1" paragraph>
        We may share personal information about you as follows: with third party
        vendors and other service providers who need access to your information
        to carry out work on our behalf, if we believe disclosure is reasonably
        necessary to comply with any applicable law, regulation, legal process
        or governmental request, to enforce applicable user agreements or
        policies, including our Terms of Service, and to protect us, our users
        or the public from harm or illegal activities, in connection with any
        merger, sale of assets, financing or acquisition of all or a portion of
        our business to another company, and if we notify you through our
        services that the information you provide will be shared in a particular
        manner and you provide such information. We may also share aggregated or
        anonymized information that does not directly identify you.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Third Party Analytics
      </Typography>
      <Typography variant="body1" paragraph>
        We may allow third parties to provide analytics services. These third
        parties may use cookies, web beacons and other technologies to collect
        information about your use of the services and other websites, including
        your IP address, web browser, pages viewed, time spent on pages, links
        clicked and conversion information.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Security
      </Typography>
      <Typography variant="body1" paragraph>
        We take reasonable measures to help protect personal information from
        loss, theft, misuse and unauthorized access, disclosure, alteration and
        destruction.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Your Information Choices
      </Typography>
      <Typography variant="body1" paragraph>
        You may opt out of receiving any promotional emails from us by following
        the instructions in those emails. If you opt out, we may still send you
        non-promotional communications, such as those about your account or our
        ongoing business relations.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about this privacy policy, please contact us
        at: <Link href="mailto:bk10895@gmail.com">bk10895@gmail.com</Link>.
      </Typography>
    </Container>
  );
};
