import React from 'react';

import { Box, Button, Container, Typography, useTheme } from '@mui/material';

interface RecipeEditFormHeaderProps {
  title: string;
  onSave?: () => void;
  onCancel?: () => void;
}

export const RecipeEditFormHeader: React.FC<RecipeEditFormHeaderProps> = ({
  title,
  onSave,
  onCancel,
}) => {
  const theme = useTheme();

  const headerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  };

  const titleStyle: React.CSSProperties = {
    fontSize: theme.typography.h5.fontSize,
  };

  const buttonContainerStyle: React.CSSProperties = {
    display: 'flex',
    gap: theme.spacing(1),
  };

  const buttonStyle: React.CSSProperties = {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
  };

  return (
    <Container style={headerStyle}>
      <Typography style={titleStyle}>{title}</Typography>
      <Box style={buttonContainerStyle}>
        <Button
          variant="outlined"
          color="error"
          style={buttonStyle}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={buttonStyle}
          onClick={onSave}
        >
          Save
        </Button>
      </Box>
    </Container>
  );
};
