import { User } from '../../types';
import { RecipeplannerApi } from '../api-client';

export const getUserInfo = async (): Promise<User> => {
  const { data } = await RecipeplannerApi.me();
  return {
    id: data.id,
    firstName: data.first_name ?? '',
    lastName: data.last_name ?? '',
    email: data.email,
    imageUrl: data.image_url ?? '',
    createdAt: data.created_at ?? '',
    updatedAt: data.updated_at ?? '',
    supertokensId: data.supertokens_id ?? '',
  };
};
