import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../store';

interface PrivateRoutesProps {
  children: React.ReactNode;
}

export const PrivateRoutes: React.FC<PrivateRoutesProps> = ({ children }) => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  if (!isAuthenticated) {
    // Redirect to the not-authenticated page if the user is not authenticated
    return <Navigate to="/not-authenticated" />;
  }

  // Render the children components if the user is authenticated
  return <>{children}</>;
};
