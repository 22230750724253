import React from 'react';

import { Card, CardContent, Typography } from '@mui/material';

interface CategoryTileProps {
  title: string;
  color?: string;
  categoryId: string;
  onPress: (categoryId: string) => void;
}

export const CategoryTile: React.FC<CategoryTileProps> = (props) => {
  return (
    <Card
      onClick={() => props.onPress(props.categoryId)}
      sx={{
        '&.MuiCard-root': {
          cursor: 'pointer',
          backgroundColor: props.color ?? '#ffffff',
          width: '100%',
          minHeight: { xs: 150, sm: 200, md: 250 },
          m: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'transform 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        },
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: 'center',
            fontSize: {
              xs: '1.25rem',
              sm: '1.5rem',
              md: '2rem',
            },
          }}
        >
          {props.title}
        </Typography>
      </CardContent>
    </Card>
  );
};
