import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Typography } from '@mui/material';

import { getMyRecipes } from '../../api';
import { CircularLoader, PageHeader, RecipeList } from '../../components';
import { Recipe } from '../../types';

export const MyRecipesPage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [recipes, setRecipes] = useState<Recipe[]>([]);

  useEffect(() => {
    const fetchRecipes = async () => {
      setLoading(true);
      try {
        const response = await getMyRecipes();
        setRecipes(response);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchRecipes();
  }, []);

  const handlePress = (recipeId: string) => {
    navigate(`/recipes/${recipeId}`);
  };

  if (loading) return <CircularLoader />;
  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <Container>
      <PageHeader title="My Recipes" />
      <RecipeList
        recipes={recipes}
        onPress={handlePress}
        onFavoriteToggle={setRecipes}
      />
    </Container>
  );
};
