import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';

import { Ingredient } from '../../types';

interface IngredientsListProps {
  ingredients: Ingredient[];
  isEditable: boolean;
  onRemoveIngredient: (index: number) => void;
  onAddIngredient: (name: string) => void;
  onEditIngredient: (index: number, name: string) => void;
}

export const IngredientsList: React.FC<IngredientsListProps> = ({
  ingredients,
  isEditable,
  onRemoveIngredient,
  onAddIngredient,
  onEditIngredient,
}) => {
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editedIngredients, setEditedIngredients] = useState<string[]>(
    ingredients.map((ingredient) => ingredient.name),
  );
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [newIngredientName, setNewIngredientName] = useState<string>('');

  const handleStartEditing = (index: number) => {
    setEditIndex(index);
  };

  const handleSaveIngredient = (index: number) => {
    const editedIngredient = editedIngredients[index].trim();

    if (editedIngredient !== '') {
      const updatedIngredients = [...ingredients];
      updatedIngredients[index] = {
        ...updatedIngredients[index],
        name: editedIngredient,
      };
      onEditIngredient(index, editedIngredient);
      setEditIndex(null);
    }
  };

  const handleAddIngredientClick = () => {
    setDialogOpen(true);
  };

  const handleAddIngredient = () => {
    if (newIngredientName.trim() !== '') {
      onAddIngredient(newIngredientName.trim());
      setNewIngredientName('');
      setDialogOpen(false);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Typography variant="h6" textAlign="left" paddingBottom={1}>
        Ingredients
      </Typography>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        {ingredients.map((ingredient, index) => (
          <Box key={index} display="flex" alignItems="center">
            {editIndex === index ? (
              <>
                <input
                  type="text"
                  value={editedIngredients[index]}
                  onChange={(e) => {
                    const updatedIngredients = [...editedIngredients];
                    updatedIngredients[index] = e.target.value;
                    setEditedIngredients(updatedIngredients);
                  }}
                  onBlur={() => handleSaveIngredient(index)}
                />
              </>
            ) : (
              <Chip
                label={ingredient.name}
                onDelete={() => onRemoveIngredient(index)}
                onClick={() => isEditable && handleStartEditing(index)}
                color="primary"
                variant="outlined"
                sx={{
                  mr: '8px',
                  mb: '8px',
                  cursor: isEditable ? 'pointer' : 'default',
                }}
              />
            )}
          </Box>
        ))}
        {isEditable && (
          <Box display="flex" alignItems="center">
            <Chip
              icon={<AddIcon />}
              label="Add Ingredient"
              onClick={handleAddIngredientClick}
              color="primary"
              variant="outlined"
              sx={{ mr: '8px', mb: '8px', cursor: 'pointer' }}
            />
          </Box>
        )}
      </Box>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Add New Ingredient</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the name of the new ingredient:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Ingredient Name"
            value={newIngredientName}
            onChange={(e) => setNewIngredientName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAddIngredient}>Add</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
