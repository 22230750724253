import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Button, Container, Typography } from '@mui/material';

import { getRecipeById } from '../../api';
import { CircularLoader, PageHeader, RecipeInfoTile } from '../../components';
import {
  addFavoriteAsync,
  removeFavoriteAsync,
  updateFavorite,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { Recipe } from '../../types';

export const RecipeDetailsPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [recipe, setRecipe] = useState<Recipe | null>(null);
  const { recipesId } = useParams();
  const userId = useAppSelector((state) => state.auth.user?.id);
  const isAuthenticated = !!userId;
  const canEdit = isAuthenticated && recipe?.createdBy?.id === userId;

  const dispatch = useAppDispatch();

  const handleFavoriteToggle = async () => {
    if (!recipe) return;

    const updatedRecipe = { ...recipe, isFavorite: !recipe.isFavorite };
    setRecipe(updatedRecipe);

    try {
      if (updatedRecipe.isFavorite) {
        await dispatch(addFavoriteAsync(updatedRecipe));
      } else {
        await dispatch(removeFavoriteAsync(updatedRecipe));
      }
    } catch (err) {
      console.error(
        `Failed to update favorite for recipe id: ${recipe.id}`,
        err,
      );

      setRecipe(recipe);
    }
  };

  const fetchRecipeDetails = useCallback(async () => {
    if (!recipesId) {
      setError('No recipe ID provided');
      return;
    }

    setLoading(true);
    try {
      const response = await getRecipeById(recipesId);
      setRecipe(response);
      dispatch(updateFavorite(response));
    } catch (error) {
      setError(`Error fetching recipe details ${error}`);
    } finally {
      setLoading(false);
    }
  }, [dispatch, recipesId]);

  useEffect(() => {
    fetchRecipeDetails();
  }, [fetchRecipeDetails]);

  const handleRecipeUpdate = async () => {
    try {
      await fetchRecipeDetails();
    } catch (error) {
      console.error('Error updating recipe:', error);
    }
  };

  if (loading) {
    return <CircularLoader />;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <Container maxWidth="md">
      <PageHeader title="Recipe Details" />
      {recipe && (
        <>
          <RecipeInfoTile
            title={recipe.name}
            imageUrl={recipe.images[0]?.url || 'default-image-url'}
            ingredients={recipe.ingredients}
            steps={recipe.instructions}
            cookingTime={recipe.cookingTimeMinutes ?? 10}
            complexity={recipe.complexity}
            calories={recipe.caloriesEstimate ?? 100}
            cuisineType={recipe.category}
            createdBy={
              `${recipe.createdBy?.firstName} ${recipe.createdBy?.lastName}` ??
              'Unknown'
            }
            isFavorite={recipe.isFavorite}
            onFavoriteToggle={handleFavoriteToggle}
          />
          {canEdit && (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={`/recipes/${recipesId}/edit`}
              style={{ marginTop: '16px', marginBottom: '16px' }}
              onClick={handleRecipeUpdate}
            >
              Edit Recipe
            </Button>
          )}
        </>
      )}
      {!recipe && <Typography>No recipe details available</Typography>}
    </Container>
  );
};
