import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from '@mui/material';

import { Recipe } from '../../types';
import { RecipeEditFormHeader } from '../RecipeEditFormHeader';
import { IngredientsList } from './IngredientsList';
import { InstructionsList } from './InstructionsList';

interface RecipeFormProps {
  recipe: Recipe;
  isEditable: boolean;
  onSave: (editedRecipe: Recipe) => void;
  onCancel: () => void;
}

export const RecipeEditForm: React.FC<RecipeFormProps> = ({
  recipe,
  isEditable,
  onSave,
  onCancel,
}) => {
  const [editedRecipe, setEditedRecipe] = useState<Recipe | null>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteType, setDeleteType] = useState<
    'ingredient' | 'instruction' | null
  >(null);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  useEffect(() => {
    setEditedRecipe(recipe);
  }, [recipe]);

  const handleSave = () => {
    if (editedRecipe) {
      onSave(editedRecipe);
    }
  };

  const handleRemoveIngredient = (index: number) => {
    if (editedRecipe) {
      setDeleteType('ingredient');
      setDeleteIndex(index);
      setDeleteConfirmationOpen(true);
    }
  };

  const handleAddIngredient = (name: string) => {
    if (editedRecipe) {
      const updatedIngredients = [...editedRecipe.ingredients];
      const newIngredient = { id: '', name };
      updatedIngredients.push(newIngredient);
      const updatedRecipe: Recipe = {
        ...editedRecipe,
        ingredients: updatedIngredients,
      };
      setEditedRecipe(updatedRecipe);
    }
  };

  const handleEditIngredient = (index: number, name: string) => {
    if (editedRecipe) {
      const updatedIngredients = [...editedRecipe.ingredients];
      updatedIngredients[index].name = name;

      const updatedRecipe: Recipe = {
        ...editedRecipe,
        ingredients: updatedIngredients,
      };

      setEditedRecipe(updatedRecipe);
    }
  };

  const handleRemoveInstruction = (index: number) => {
    if (editedRecipe) {
      setDeleteType('instruction');
      setDeleteIndex(index);
      setDeleteConfirmationOpen(true);
    }
  };

  const handleAddInstruction = (index?: number) => {
    if (editedRecipe) {
      const updatedRecipe: Recipe = { ...editedRecipe };

      updatedRecipe.instructions = [...editedRecipe.instructions];

      if (index !== undefined) {
        updatedRecipe.instructions.splice(index, 0, { id: '', text: '' });
      } else {
        updatedRecipe.instructions.push({ id: '', text: '' });
      }

      setEditedRecipe(updatedRecipe);
    }
  };

  const handleEditInstruction = (index: number, text: string) => {
    if (editedRecipe) {
      const updatedItems = [...editedRecipe.instructions];
      updatedItems[index] = {
        ...updatedItems[index],
        text,
      };
      setEditedRecipe({
        ...editedRecipe,
        instructions: updatedItems,
      });
    }
  };

  const handleConfirmDelete = () => {
    if (editedRecipe && deleteType !== null && deleteIndex !== null) {
      let updatedRecipe: Recipe | null = null;

      if (deleteType === 'ingredient') {
        updatedRecipe = {
          ...editedRecipe,
          ingredients: [...editedRecipe.ingredients],
        };
        updatedRecipe.ingredients.splice(deleteIndex, 1);
      } else if (deleteType === 'instruction') {
        updatedRecipe = {
          ...editedRecipe,
          instructions: [...editedRecipe.instructions],
        };
        updatedRecipe.instructions.splice(deleteIndex, 1);
      }

      if (updatedRecipe) {
        setEditedRecipe(updatedRecipe);
        setDeleteConfirmationOpen(false);
      }
    }
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" flexDirection="column" alignItems="stretch">
        {editedRecipe && (
          <>
            <RecipeEditFormHeader
              title="Edit Recipe"
              onSave={handleSave}
              onCancel={onCancel}
            />

            <TextField
              label="Recipe Name"
              value={editedRecipe.name}
              onChange={(e) => {
                if (editedRecipe) {
                  const updatedRecipe = {
                    ...editedRecipe,
                    name: e.target.value,
                  };
                  setEditedRecipe(updatedRecipe);
                }
              }}
              disabled={!isEditable}
              fullWidth
              variant="outlined"
              sx={{ mb: '16px' }}
            />

            <Tooltip title="This field cannot be changed" placement="top" arrow>
              <TextField
                label="Cuisine Type*"
                error
                value={editedRecipe.category.name}
                fullWidth
                variant="outlined"
                sx={{ mb: '16px' }}
                disabled
              />
            </Tooltip>

            <TextField
              label="Calories Estimate"
              value={editedRecipe.caloriesEstimate.toString()}
              onChange={(e) => {
                const value = e.target.value.trim();
                const updatedRecipe = {
                  ...editedRecipe,
                  caloriesEstimate: value === '' ? 0 : parseInt(value, 10),
                };
                setEditedRecipe(updatedRecipe);
              }}
              disabled={!isEditable}
              fullWidth
              variant="outlined"
              sx={{ mb: '16px' }}
            />
            <TextField
              label="Complexity"
              value={editedRecipe.complexity}
              onChange={(e) => {
                if (editedRecipe) {
                  const updatedRecipe = {
                    ...editedRecipe,
                    complexity: e.target.value,
                  };
                  setEditedRecipe(updatedRecipe);
                }
              }}
              disabled={!isEditable}
              fullWidth
              variant="outlined"
              sx={{ mb: '16px' }}
            />
            <TextField
              label="Cooking Time (minutes)"
              value={editedRecipe.cookingTimeMinutes.toString()}
              onChange={(e) => {
                const value = e.target.value.trim();
                const updatedRecipe = {
                  ...editedRecipe,
                  cookingTimeMinutes: value === '' ? 0 : parseInt(value, 10),
                };
                setEditedRecipe(updatedRecipe);
              }}
              disabled={!isEditable}
              fullWidth
              variant="outlined"
              sx={{ mb: '16px' }}
            />
            <Tooltip title="This field cannot be changed" placement="top" arrow>
              <TextField
                label="Created By*"
                value={
                  `${editedRecipe.createdBy?.firstName} ${editedRecipe.createdBy?.lastName}` ??
                  ''
                }
                fullWidth
                variant="outlined"
                sx={{ mb: '16px' }}
                disabled
                error
              />
            </Tooltip>

            <IngredientsList
              ingredients={editedRecipe.ingredients}
              isEditable={isEditable}
              onRemoveIngredient={handleRemoveIngredient}
              onAddIngredient={handleAddIngredient}
              onEditIngredient={handleEditIngredient}
            />

            <InstructionsList
              instructions={editedRecipe.instructions}
              isEditable={isEditable}
              onRemoveInstruction={handleRemoveInstruction}
              onAddInstruction={handleAddInstruction}
              onEditInstruction={handleEditInstruction}
            />
          </>
        )}
      </Box>

      <Dialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this{' '}
            {deleteType === 'ingredient' ? 'ingredient' : 'instruction'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteConfirmationOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
