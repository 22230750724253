/* tslint:disable */

/* eslint-disable */

/**
 * meal-planner-api
 * TypeScript + Prisma + Postgres + Express API Server
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';

import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, BaseAPI, COLLECTION_FORMATS, RequiredError } from './base';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from './common';
import type { Configuration } from './configuration';

/**
 *
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
  /**
   *
   * @type {string}
   * @memberof CategoryDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CategoryDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CategoryDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CategoryDto
   */
  name: string;
}
/**
 *
 * @export
 * @interface CategoryInputDto
 */
export interface CategoryInputDto {
  /**
   *
   * @type {string}
   * @memberof CategoryInputDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CategoryInputDto
   */
  name: string;
}
/**
 *
 * @export
 * @interface CategoryWithRecipesDto
 */
export interface CategoryWithRecipesDto {
  /**
   *
   * @type {string}
   * @memberof CategoryWithRecipesDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CategoryWithRecipesDto
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CategoryWithRecipesDto
   */
  updated_at?: string;
  /**
   *
   * @type {string}
   * @memberof CategoryWithRecipesDto
   */
  name: string;
  /**
   *
   * @type {Array<RecipeDto>}
   * @memberof CategoryWithRecipesDto
   */
  recipes?: Array<RecipeDto>;
}
/**
 *
 * @export
 * @interface CreateRequest
 */
export interface CreateRequest {
  /**
   *
   * @type {string}
   * @memberof CreateRequest
   */
  query: string;
}
/**
 *
 * @export
 * @interface IngredientDto
 */
export interface IngredientDto {
  /**
   *
   * @type {string}
   * @memberof IngredientDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof IngredientDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IngredientDto
   */
  quantity: string;
}
/**
 *
 * @export
 * @interface IngredientInputDto
 */
export interface IngredientInputDto {
  /**
   *
   * @type {string}
   * @memberof IngredientInputDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof IngredientInputDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof IngredientInputDto
   */
  quantity: string;
}
/**
 *
 * @export
 * @interface InstructionDto
 */
export interface InstructionDto {
  /**
   *
   * @type {string}
   * @memberof InstructionDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof InstructionDto
   */
  text: string;
}
/**
 *
 * @export
 * @interface InstructionInputDto
 */
export interface InstructionInputDto {
  /**
   *
   * @type {string}
   * @memberof InstructionInputDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof InstructionInputDto
   */
  text: string;
}
/**
 *
 * @export
 * @interface RecipeDto
 */
export interface RecipeDto {
  /**
   *
   * @type {string}
   * @memberof RecipeDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof RecipeDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RecipeDto
   */
  description: string;
  /**
   *
   * @type {CategoryDto}
   * @memberof RecipeDto
   */
  category: CategoryDto;
  /**
   *
   * @type {Array<IngredientDto>}
   * @memberof RecipeDto
   */
  ingredients: Array<IngredientDto>;
  /**
   *
   * @type {Array<InstructionDto>}
   * @memberof RecipeDto
   */
  instructions: Array<InstructionDto>;
  /**
   *
   * @type {number}
   * @memberof RecipeDto
   */
  cooking_time_minutes: number;
  /**
   *
   * @type {string}
   * @memberof RecipeDto
   */
  complexity: string;
  /**
   *
   * @type {number}
   * @memberof RecipeDto
   */
  calories_estimate: number;
  /**
   *
   * @type {Array<RecipeImageDto>}
   * @memberof RecipeDto
   */
  images: Array<RecipeImageDto>;
  /**
   *
   * @type {UserDto}
   * @memberof RecipeDto
   */
  created_by: UserDto;
  /**
   *
   * @type {boolean}
   * @memberof RecipeDto
   */
  isFavorite?: boolean;
}
/**
 *
 * @export
 * @interface RecipeImageDto
 */
export interface RecipeImageDto {
  /**
   *
   * @type {string}
   * @memberof RecipeImageDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof RecipeImageDto
   */
  url: string;
}
/**
 *
 * @export
 * @interface RecipeUpdateInputDto
 */
export interface RecipeUpdateInputDto {
  /**
   *
   * @type {string}
   * @memberof RecipeUpdateInputDto
   */
  category_id?: string;
  /**
   *
   * @type {CategoryInputDto}
   * @memberof RecipeUpdateInputDto
   */
  category?: CategoryInputDto;
  /**
   *
   * @type {string}
   * @memberof RecipeUpdateInputDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RecipeUpdateInputDto
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof RecipeUpdateInputDto
   */
  cooking_time_minutes?: number;
  /**
   *
   * @type {string}
   * @memberof RecipeUpdateInputDto
   */
  complexity?: string;
  /**
   *
   * @type {number}
   * @memberof RecipeUpdateInputDto
   */
  calories_estimate?: number;
  /**
   *
   * @type {Array<IngredientInputDto>}
   * @memberof RecipeUpdateInputDto
   */
  ingredients?: Array<IngredientInputDto>;
  /**
   *
   * @type {Array<InstructionInputDto>}
   * @memberof RecipeUpdateInputDto
   */
  instructions?: Array<InstructionInputDto>;
  /**
   *
   * @type {UserDto}
   * @memberof RecipeUpdateInputDto
   */
  created_by?: UserDto;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  updated_at: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  first_name: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  last_name: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  image_url: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  supertokens_id: string;
}
/**
 *
 * @export
 * @interface UserDto
 */
export interface UserDto {
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof UserDto
   */
  image_url: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFavoriteRecipe: async (
      recipeId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'recipeId' is not null or undefined
      assertParamExists('addFavoriteRecipe', 'recipeId', recipeId);
      const localVarPath = `/api/users/favorite-recipes/{recipeId}`.replace(
        `{${'recipeId'}}`,
        encodeURIComponent(String(recipeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateRequest} createRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createRequest: CreateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createRequest' is not null or undefined
      assertParamExists('create', 'createRequest', createRequest);
      const localVarPath = `/api/recipes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCategories: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRecipes: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/recipes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {boolean} [includeRecipes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryById: async (
      id: string,
      includeRecipes?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getCategoryById', 'id', id);
      const localVarPath = `/api/categories/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (includeRecipes !== undefined) {
        localVarQueryParameter['includeRecipes'] = includeRecipes;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFavoriteRecipes: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/favorite-recipes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyRecipes: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/my-recipes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecipeById: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getRecipeById', 'id', id);
      const localVarPath = `/api/recipes/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecipesByCategoryId: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getRecipesByCategoryId', 'id', id);
      const localVarPath = `/api/categories/{id}/recipes`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} createdById
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecipesByUserId: async (
      createdById: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createdById' is not null or undefined
      assertParamExists('getRecipesByUserId', 'createdById', createdById);
      const localVarPath = `/api/recipes/user/{created_by_id}`.replace(
        `{${'created_by_id'}}`,
        encodeURIComponent(String(createdById)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    me: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/users/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeFavoriteRecipe: async (
      recipeId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'recipeId' is not null or undefined
      assertParamExists('removeFavoriteRecipe', 'recipeId', recipeId);
      const localVarPath = `/api/users/favorite-recipes/{recipeId}`.replace(
        `{${'recipeId'}}`,
        encodeURIComponent(String(recipeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {RecipeUpdateInputDto} recipeUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRecipe: async (
      id: string,
      recipeUpdateInputDto: RecipeUpdateInputDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateRecipe', 'id', id);
      // verify required parameter 'recipeUpdateInputDto' is not null or undefined
      assertParamExists(
        'updateRecipe',
        'recipeUpdateInputDto',
        recipeUpdateInputDto,
      );
      const localVarPath = `/api/recipes/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        recipeUpdateInputDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addFavoriteRecipe(
      recipeId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addFavoriteRecipe(recipeId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreateRequest} createRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createRequest: CreateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(
        createRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCategories(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CategoryDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllCategories(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllRecipes(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<RecipeDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllRecipes(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} id
     * @param {boolean} [includeRecipes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCategoryById(
      id: string,
      includeRecipes?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CategoryWithRecipesDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryById(
        id,
        includeRecipes,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFavoriteRecipes(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<RecipeDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getFavoriteRecipes(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMyRecipes(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<RecipeDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getMyRecipes(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecipeById(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeById(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecipesByCategoryId(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<RecipeDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRecipesByCategoryId(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} createdById
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecipesByUserId(
      createdById: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<RecipeDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRecipesByUserId(
          createdById,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async me(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.me(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeFavoriteRecipe(
      recipeId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeFavoriteRecipe(recipeId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} id
     * @param {RecipeUpdateInputDto} recipeUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRecipe(
      id: string,
      recipeUpdateInputDto: RecipeUpdateInputDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecipeDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateRecipe(
        id,
        recipeUpdateInputDto,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {string} recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFavoriteRecipe(recipeId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .addFavoriteRecipe(recipeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateRequest} createRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      createRequest: CreateRequest,
      options?: any,
    ): AxiosPromise<RecipeDto> {
      return localVarFp
        .create(createRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCategories(options?: any): AxiosPromise<Array<CategoryDto>> {
      return localVarFp
        .getAllCategories(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRecipes(options?: any): AxiosPromise<Array<RecipeDto>> {
      return localVarFp
        .getAllRecipes(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {boolean} [includeRecipes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryById(
      id: string,
      includeRecipes?: boolean,
      options?: any,
    ): AxiosPromise<CategoryWithRecipesDto> {
      return localVarFp
        .getCategoryById(id, includeRecipes, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFavoriteRecipes(options?: any): AxiosPromise<Array<RecipeDto>> {
      return localVarFp
        .getFavoriteRecipes(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyRecipes(options?: any): AxiosPromise<Array<RecipeDto>> {
      return localVarFp
        .getMyRecipes(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecipeById(id: string, options?: any): AxiosPromise<RecipeDto> {
      return localVarFp
        .getRecipeById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecipesByCategoryId(
      id: string,
      options?: any,
    ): AxiosPromise<Array<RecipeDto>> {
      return localVarFp
        .getRecipesByCategoryId(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} createdById
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecipesByUserId(
      createdById: string,
      options?: any,
    ): AxiosPromise<Array<RecipeDto>> {
      return localVarFp
        .getRecipesByUserId(createdById, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    me(options?: any): AxiosPromise<User> {
      return localVarFp.me(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeFavoriteRecipe(recipeId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .removeFavoriteRecipe(recipeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {RecipeUpdateInputDto} recipeUpdateInputDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRecipe(
      id: string,
      recipeUpdateInputDto: RecipeUpdateInputDto,
      options?: any,
    ): AxiosPromise<RecipeDto> {
      return localVarFp
        .updateRecipe(id, recipeUpdateInputDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {string} recipeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public addFavoriteRecipe(recipeId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .addFavoriteRecipe(recipeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateRequest} createRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public create(createRequest: CreateRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .create(createRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getAllCategories(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getAllCategories(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getAllRecipes(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getAllRecipes(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {boolean} [includeRecipes]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getCategoryById(
    id: string,
    includeRecipes?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .getCategoryById(id, includeRecipes, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getFavoriteRecipes(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getFavoriteRecipes(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getMyRecipes(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getMyRecipes(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getRecipeById(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getRecipeById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getRecipesByCategoryId(id: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getRecipesByCategoryId(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} createdById
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getRecipesByUserId(createdById: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getRecipesByUserId(createdById, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public me(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .me(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} recipeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public removeFavoriteRecipe(recipeId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .removeFavoriteRecipe(recipeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {RecipeUpdateInputDto} recipeUpdateInputDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateRecipe(
    id: string,
    recipeUpdateInputDto: RecipeUpdateInputDto,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .updateRecipe(id, recipeUpdateInputDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
