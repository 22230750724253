import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';

import { Instruction } from '../../types';

interface InstructionsListProps {
  instructions: Instruction[];
  isEditable: boolean;
  onRemoveInstruction: (index: number) => void;
  onAddInstruction: (index?: number) => void;
  onEditInstruction: (index: number, text: string) => void;
}

export const InstructionsList: React.FC<InstructionsListProps> = ({
  instructions,
  isEditable,
  onRemoveInstruction,
  onAddInstruction,
  onEditInstruction,
}) => {
  const theme = useTheme();

  const addButtonStyle: React.CSSProperties = {
    minWidth: 0,
    padding: theme.spacing(1),
    borderColor: theme.palette.primary.main,
  };

  const deleteButtonStyle: React.CSSProperties = {
    minWidth: 0,
    padding: theme.spacing(1),
    borderColor: theme.palette.error.main,
  };

  return (
    <>
      <Typography variant="h6" textAlign="left" paddingBottom={1}>
        Instructions
      </Typography>
      {instructions.map((instruction, index) => (
        <Box key={index} display="flex" alignItems="center" marginBottom="16px">
          <TextField
            label={`Instruction ${index + 1}`}
            value={instruction.text}
            onChange={(e) => onEditInstruction(index, e.target.value)}
            disabled={!isEditable}
            fullWidth
            multiline
            variant="outlined"
            sx={{ flexGrow: 1 }}
          />
          {isEditable && (
            <Box marginLeft="8px">
              <Button
                variant="outlined"
                color="primary"
                style={addButtonStyle}
                onClick={() => onAddInstruction(index + 1)}
                aria-label="Add Instruction"
              >
                <AddIcon />
              </Button>
            </Box>
          )}
          {isEditable && (
            <Box marginLeft="8px">
              <Button
                variant="outlined"
                color="error"
                style={deleteButtonStyle}
                onClick={() => onRemoveInstruction(index)}
                aria-label="Delete Instruction"
              >
                <DeleteIcon />
              </Button>
            </Box>
          )}
        </Box>
      ))}
    </>
  );
};
