import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Typography } from '@mui/material';

export const Footer = () => {
  return (
    <Box
      component="footer"
      bgcolor="primary.main"
      color="white"
      textAlign="center"
      width="100%"
      paddingY={1}
    >
      <Typography variant="body2">
        <Link
          component={RouterLink}
          to="/privacy-policy"
          color="inherit"
          underline="hover"
          mx={1}
        >
          Privacy Policy
        </Link>
        <Link
          component={RouterLink}
          to="/terms-and-conditions"
          color="inherit"
          underline="hover"
          mx={1}
        >
          Terms and Conditions
        </Link>
      </Typography>
      <Typography variant="body2">
        © {new Date().getFullYear()} Meal Planner App
      </Typography>
    </Box>
  );
};
