import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Container, Typography } from '@mui/material';

import { getFavoriteRecipes } from '../../api';
import { PageHeader, RecipeList } from '../../components';
import { useAppSelector } from '../../store';
import { Recipe } from '../../types';

export const FavoriteRecipesPage: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const [favoriteRecipes, setFavoriteRecipes] = useState<Recipe[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const recipes = await getFavoriteRecipes();
        console.log('recipes', recipes);
        setFavoriteRecipes(recipes);
      } catch (error) {
        console.error('Error fetching favorite recipes:', error);
      }
    };

    fetchData();
  }, []);

  const handlePress = (recipeId: string) => {
    navigate(`/recipes/${recipeId}`);
  };

  const navigateToAuthentication = () => {
    navigate('/auth');
  };

  return (
    <Container>
      <PageHeader title="Favorite Recipes" />
      {isAuthenticated ? (
        favoriteRecipes.length === 0 ? (
          <Typography>No favorite recipes yet.</Typography>
        ) : (
          <RecipeList
            recipes={favoriteRecipes}
            onPress={handlePress}
            onFavoriteToggle={setFavoriteRecipes}
          />
        )
      ) : (
        <>
          <Typography>
            You need to log in to view your favorite recipes.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={navigateToAuthentication}
          >
            Log in
          </Button>
        </>
      )}
    </Container>
  );
};
