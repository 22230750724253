import React from 'react';
import { Link } from 'react-router-dom';

import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
} from '@mui/material';

import { NavMenu } from './NavMenu';
import { UserMenu } from './UserMenu';

const NAV_PAGES = [
  'Recipes',
  'Categories',
  'Favorites',
  'Add Recipe',
  'My Recipes',
];

export const Navbar = () => {
  const getPath = (page: string) =>
    `/${page.toLowerCase().replace(/\s+/g, '')}`;

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* NavMenu for small screens */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <NavMenu pages={NAV_PAGES} />
          </Box>

          {/* MEAL PLANNER text */}
          <Box sx={{ flexGrow: 1, alignItems: 'flex-start' }}>
            <Typography
              variant="h6"
              noWrap
              component={Link}
              to="/"
              sx={{
                color: 'inherit',
                textDecoration: 'none',
                fontWeight: '550',
              }}
            >
              MEAL PLANNER
            </Typography>
          </Box>

          {/* UserMenu for small screens */}
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <UserMenu />
          </Box>

          {/* Nav buttons for large screens */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {NAV_PAGES.map((page) => (
              <Button
                key={page}
                component={Link}
                to={getPath(page)}
                sx={{ my: 2, mx: 1, color: 'white' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          {/* UserMenu for large screens */}
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <UserMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
