import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getUserInfo } from '../api';
import { setAuthUser, useAppDispatch } from '../store';

interface AuthContextType {
  isReady: boolean;
  refreshUserInfo: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isReady, setIsReady] = useState(false);
  const dispatch = useAppDispatch();

  const refreshUserInfo = useCallback(async () => {
    try {
      const userInfo = await getUserInfo();
      dispatch(setAuthUser(userInfo));
    } catch (error) {
      console.error('Error fetching user info:', error);
      dispatch(setAuthUser(null));
    } finally {
      setIsReady(true);
    }
  }, [dispatch]);

  const contextValue = useMemo(
    () => ({ refreshUserInfo, isReady }),
    [refreshUserInfo, isReady],
  );

  useEffect(() => {
    refreshUserInfo();
  }, [refreshUserInfo]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};
