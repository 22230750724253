import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Container, Typography } from '@mui/material';

import { getRecipesByCategoryId } from '../../api';
import { CircularLoader, PageHeader, RecipeList } from '../../components';
import { Recipe } from '../../types';

export const RecipeListPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const navigate = useNavigate();
  const { categoryId } = useParams();

  console.log(recipes);

  useEffect(() => {
    const fetchRecipes = async () => {
      if (typeof categoryId === 'string') {
        setLoading(true);
        try {
          const response = await getRecipesByCategoryId(categoryId);
          setRecipes(response);
        } catch (err) {
          setError(err as string);
        } finally {
          setLoading(false);
        }
      } else {
        setError(`Category ID ${categoryId} not found`);
      }
    };

    fetchRecipes();
  }, [categoryId]);

  const handlePress = (recipeId: string) => {
    navigate(`/recipes/${recipeId}`);
  };

  if (loading) return <CircularLoader />;
  if (error) {
    return <Typography>Error: {error}</Typography>;
  }
  const categoryName = recipes.length > 0 ? recipes[0].category.name : '';

  return (
    <Container>
      <PageHeader title={`${categoryName} Recipes`} />
      <RecipeList
        recipes={recipes}
        onPress={handlePress}
        onFavoriteToggle={(recipe) => {
          console.log('Favorite toggle', recipe);
        }}
      />
    </Container>
  );
};
